import React from "react";
import "./home.scss";
import Section1 from "../../components/section1/Section1";
import Section2 from "../../components/section2/Section2";
import Section3 from "../../components/section3/Section3";
import Section5 from "../../components/section5/Section5";
import Footer from "../../components/footer/Footer";
import Section4 from "../../components/section4/Section4";
import sallaimg from '../../assets/imgs/salla.png'
const Home = () => {
  return (
    <div className='home'>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <div style={{padding:' 5px 40px', color:'#253439',display:'flex',flexDirection:'column',width:'100%'}}>
        <h1  className="platform">
        Supported platforms
        </h1>
        <div style={{width:'100%',display:'flex' ,justifyContent:'center',alignItems:'center'}}>
        <img src={sallaimg} style={{width:'100%',maxWidth:'400px'}}/>
        </div>
      </div>
      <Section5 />
      <Footer />
    </div>
  );
};

export default Home;