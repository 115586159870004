import React, { useEffect, useState } from "react";
import "./section5.scss";
import Whatsapp from "../../assets/imgs/whatsapp.png";
import Mail from "../../assets/imgs/mail.png";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import ReCAPTCHA  from "react-google-recaptcha";
const Section5 = () => {
  const [name,setName]=useState('')
  const [mobile,setMobile]=useState('')
  const [message,setMessage]=useState('')
  const [email,setEmail]=useState('')
  const [error,setError] = useState()
  const [success,setSuccess] = useState()
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  useEffect(() => {
    AOS.init();
  }, []);
  const handelSubmit = async(e)=>{
    setError('')
    e.preventDefault()

    if (!recaptchaValue) {
      
      return setError('Verfiy You Are Not Robot First')
    }
    try {
      const { status } = await axios.post('https://apis.autodrop.me/contact', {
        name,email,message,
        mobile: mobile?.replace(/\s/gi, '')
      });
      if(status === 200){
        setSuccess(true)
        setEmail('')
        setTimeout(()=>setSuccess(false),3000)
        setName('')
        setMobile('')
        setMessage('')

      }
      
    } catch (error) {

      setError(error.response.data.message)
    }

  }
  return (
    <section className='section5' id='contact'>
      <div className='section5Container container'>
        <h2>
          Do you have an inquiry about Auto Drop?
        </h2>

        <div className='item-container'>
          {" "}
          <div data-aos='fade-up' className='left'>
            <form action='' onSubmit={(e)=>handelSubmit(e)}>
              <div className='input'>
                <label htmlFor='name'>Full Name</label>
                <input placeholder='Enter Your Name' value={name} type='text'  onChange={(e)=>setName(e.target.value)} required/>
              </div>
              <div className='input'>
                <label htmlFor='email'>Email Address </label>
                <input
                  placeholder='example@gmail.com'
                  type='email'
                  id='email'
                  name="email"
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  required
                />
              </div>
              <div className='input'>
                <label htmlFor='phone'>Phone Number</label>
                <input placeholder='+9660000000' value={mobile} type='number' onChange={(e)=>setMobile(e.target.value)} required/>
              </div>
              <div className='input'>
                <label htmlFor='message'>Message</label>
                <textarea
                  placeholder='Type your message here'
                  cols='30'
                  rows='10'
                  name="message"
                  value={message}
                  onChange={(e)=>setMessage(e.target.value)}
                  required
                ></textarea>
                
                <div className="input">
              <ReCAPTCHA sitekey="6LeAVDYpAAAAAPf66yTFHC_iSLRxrhBhNIVY31X-" onChange={setRecaptchaValue} />
              </div>
                {success && <div className="success">Message Send Successful.. </div>}
                {error && <div className="error">{error}</div>}
                <button>Send Message</button>
              </div>
            </form>
          </div>
          <div data-aos='fade-up' className='right'>
            <div className='right-container'>
              <div className='item'>
                <a
                  href='https://wa.me/+201557053437'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {" "}
                  <img src={Whatsapp} alt='' />
                </a>

                <p>+201557053437</p>
              </div>
              <div className='item'>
                <a
                  href='mailto:support@autodrop.me'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={Mail} alt='' />
                </a>

                <p>support@autodrop.me</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section5;
